
body, html {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
    width: 100%;
    /* background-color: rgba(8, 46, 45, 1);
    background: linear-gradient(90deg, rgba(8, 46, 45, 0.7),rgba(8, 46, 45, 1)) center;
    background-repeat: no-repeat;
    background-size: 100%; */

    background-color: #e5e5e5 !important;

    /* background: linear-gradient(90deg, #c05f27 1px, transparent 1%) center, linear-gradient(#000000 1px, transparent 1%) center, #000000;
    background-size: 1px 22px; */

    /* background: linear-gradient(to bottom, #ffe599 , #4a3a0a);
    background-color: #4a3a0a !important;
    background-repeat: no-repeat;
    background-size: 100%; */

    /* background-image: linear-gradient(to bottom, rgba(8, 46, 45, 0.7) ,rgba(8, 46, 45, 1));
    background-color: rgba(8, 46, 45, 1) !important;
    background-repeat: no-repeat;
    background-size: 100%; */
}

body .MuiAutocomplete-popper {
    width: auto !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sexy_line { 
    margin: 25px 0;
    height: 1px;
    background: black;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, black));
}
