.slide-placeholder {
	background: #DADADA;
	position: relative;
}
.slide-placeholder:after {
	content: " ";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 21px;
	background-color: #FFF;
}